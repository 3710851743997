import React, { useState, useEffect } from 'react';
import '../App.css';

function Cursor() {

    const [position, setPosition] = useState({ x: 0, y: 0 });

    const onMouseMove = (e) => {
        setPosition({ x: e.clientX, y: e.clientY });
    };
    const addEventListeners = () => {
        document.addEventListener("mousemove", onMouseMove);
    };

    const removeEventListeners = () => {
        document.removeEventListener("mousemove", onMouseMove);
    };

    useEffect(() => {
        addEventListeners();
        return () => removeEventListeners();
    }, []);

    
    return (
        <div className="cursor" style={{ left: `${position.x}px`, top: `${position.y}px` }}>
        </div>
    )

}

export default Cursor