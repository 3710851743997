import React, { useState } from 'react';
/* import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'; */
/* Screens */
import Header from './components/Header';
import Home from './screens/Home';
import Contact from './screens/Contact';
import About from './screens/About';
import Cursor from './components/Cursor';

function App() {

  const [screen, setScreen] = useState(<Home />)
  const handleClick = (screenName) => {

    if (screenName === 'About') {
      setScreen(<About />)
    } else if (screenName === 'Contact'){
      setScreen(<Contact />)
    } else if (screenName === 'Home') {
      setScreen(<Home />)
    }
  }
  return (
    <div style={{height:'100vh'}}>
      <Cursor />
      <Header handleClick={handleClick} />
      {screen}
    </div >
  );
}


export default App;
