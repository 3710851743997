import React from 'react';

function About() {

    return (
            <div style={styles.container}>
                <div style={styles.text}>
                    <p className='fade-in-top' style={{ textAlign: 'center' }}>
                        Passionate about UX design & code, I develop web and mobile applications from mockups to front-end and back-end programming.
                        I embrace minimalism and user centered design. I work hand in hand with my clients to create digital experience that are meaningful and memorable for people.
                    </p>
                </div>
            </div>
    )
}
const styles = {
    container: {
        height: '105vh',
        background: 'radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(245,245,245,1) 100%)',
        position: 'relative',
        top: '-100px', /* .navbar has margin-bottom: 20px; */
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    text: {
        alignSelf: 'center',
        width: "60%",
        fontSize: '20px',
        fontWeight: '300',
        letterSpacing: '1px'
    }
}
export default About