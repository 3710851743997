import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring'
import Lottie from 'react-lottie';
import * as animationData from '../lotties/scroll.json';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import '../App.css';

function Home() {
    const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
    const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))

    const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`
    const trans2 = (x, y) => `translate3d(${x / 8 + 35}px,${y / 8 - 230}px,0)`
    const trans3 = (x, y) => `translate3d(${x / 6 - 250}px,${y / 6 - 200}px,0)`
    const trans4 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`

    // Animation d'entrée de la section portfolio dès que le user scroll sur la section
    const [portfolioStyle, setPortfolioStyle] = useState('container');

    const handleScroll = () => {
        setPortfolioStyle('container scale-up-center')
    }

    window.addEventListener('scroll', handleScroll);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData.default,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }


    return (
        <div style={{ height: '100vh' }}>
            <div>
                <div style={styles.header} className="mobile-header">
                    <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                        <Grid item xs={12} sm={8} className="text">
                            <h1 className='fade-in-top' >Hi, I am Chanez, I design and build web and mobile applications, usually with React and React Native.
                            </h1>
                            <h4 className='fade-in-top' >Scroll down to see some of my work!</h4>
                        </Grid>
                        <Grid item xs={12} sm={4} style={{ textAlign: 'center', alignSelf: 'center' }}>
              
                        </Grid>

                    </Grid>
                    <AnchorLink href='#gallery' style={{ alignSelf: 'center' }}>
                        <Lottie
                            options={defaultOptions}
                            height={80}
                            width={80}
                            style={{ position: 'relative', top: '80px' }}
                        /></AnchorLink>
                </div>
                <section id='gallery' style={styles.gallery}>
                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }} style={styles.grid}>
                        <Masonry gutter="10px">
                            <div className={portfolioStyle} >
                                <img alt="TeamMates-Mockup" className="image" src='../TeamMates-2.jpg' />
                                <div className="description">
                                    <h3>Mobile app Team Mates</h3>
                                    <div className="badges">
                                        <Chip style={{ backgroundColor: 'black', color: 'white', marginRight: 5, marginBottom: 5 }} label="Node.js" />
                                        <Chip style={{ backgroundColor: 'black', color: 'white', marginRight: 5, marginLeft: 5, marginBottom: 5 }} label="React Native" />
                                        <Chip style={{ backgroundColor: 'black', color: 'white', marginRight: 5, marginLeft: 5, marginBottom: 5 }} label="Express" />
                                        <Chip style={{ backgroundColor: 'black', color: 'white', marginRight: 5, marginLeft: 5, marginBottom: 5 }} label="MongoDB" />
                                        <Chip style={{ backgroundColor: 'black', color: 'white', marginRight: 5, marginLeft: 5, marginBottom: 5 }} label="Redux" />
                                    </div>
                                </div>
                            </div>
                            <div className={portfolioStyle}>
                                <img alt="Sam-Mockup" className="image" src='../sam-2.jpg' />
                                <div className="description">
                                    <h3>Corporate website Société à mission</h3>
                                    <div className="badges">
                                        <Chip style={{ backgroundColor: 'black', color: 'white', marginRight: 5 }} label="Wordpress" />
                                        <Chip style={{ backgroundColor: 'black', color: 'white', marginRight: 5, marginLeft: 5 }} label="Elementor" />
                                    </div>

                                </div>
                            </div>

                            <div className={portfolioStyle}>
                                <img alt="Monny-Mockup" className="image" src='../monny-atelier.jpg' />
                                <div className="description">
                                    <h3>Corporate website Monny Atelier</h3>
                                    <div className="badges">
                                        <Chip style={{ backgroundColor: 'black', color: 'white', marginRight: 5 }} label="Wordpress" />
                                        <Chip style={{ backgroundColor: 'black', color: 'white', marginRight: 5, marginLeft: 5 }} label="Elementor" />
                                    </div>

                                </div>
                            </div>

                            <div className={portfolioStyle}>
                                <img alt="Symmetricc-Mockup" className="image" src='../Symmetricc-2.jpg' />
                                <div className="description">
                                    <h3>Corporate website Symmetric Consulting</h3>
                                    <div className="badges">
                                        <Chip style={{ backgroundColor: 'black', color: 'white', marginRight: 5 }} label="Wordpress" />
                                        <Chip style={{ backgroundColor: 'black', color: 'white', marginRight: 5, marginLeft: 5 }} label="Elementor" />
                                    </div>

                                </div>
                            </div>

                            <div className={portfolioStyle}>
                                <img alt="Cosme-Mockup" className="image" src='../cosme.jpg' />
                                <div className="description">
                                    <h3>Template E-shop</h3>
                                    <div className="badges">
                                        <Chip style={{ backgroundColor: 'black', color: 'white', marginRight: 5 }} label="Node.js" />
                                        <Chip style={{ backgroundColor: 'black', color: 'white', marginRight: 5, marginLeft: 5 }} label="React.js" />
                                        <Chip style={{ backgroundColor: 'black', color: 'white', marginRight: 5, marginLeft: 5 }} label="Express" />
                                        <Chip style={{ backgroundColor: 'black', color: 'white', marginRight: 5, marginLeft: 5 }} label="MongoDB" />
                                    </div>

                                </div>
                            </div>

                            <div className={portfolioStyle}>
                                <img alt="RGestion-Mockup" className="image" src='../R-GESTION-2.jpg' />
                                <div className="description">
                                    <h3>Corporate website R Gestion</h3>
                                    <div className="badges">
                                        <Chip style={{ backgroundColor: 'black', color: 'white', marginRight: 5 }} label="Wordpress" />
                                        <Chip style={{ backgroundColor: 'black', color: 'white', marginRight: 5, marginLeft: 5 }} label="Elementor" />
                                    </div>
                                </div>
                            </div>
                            <div className={portfolioStyle}>
                                <img alt="yaacom-Mockup" className="image" src='../yaacom.jpg' />
                                <div className="description">
                                    <h3>Corporate website Yaacom</h3>
                                    <div className="badges">
                                        <Chip style={{ backgroundColor: 'black', color: 'white', marginRight: 5 }} label="Wordpress" />
                                        <Chip style={{ backgroundColor: 'black', color: 'white', marginRight: 5, marginLeft: 5 }} label="Elementor" />
                                    </div>
                                </div>
                            </div>
                        </Masonry>
                    </ResponsiveMasonry>
                </section>
            </div >
        </div >
    )
}

export default Home

const styles = {
    header: {
        height: '90vh',
        background: 'radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(245,245,245,1) 100%)',
        position: 'relative',
        top: '-100px', /* .navbar has margin-bottom: 20px; */
        paddingTop: '200px', /* .navbar has margin-bottom: 20px; */
        marginBottom: '-100px', /* .navbar has margin-bottom: 20px; */
    },
    emoji: {
        height: '200px',
        witdh: '200px',
        backgroundImage: 'url("../chanez-sticker-1.png")',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    },
    gallery: {
        background: 'radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(245,245,245,1) 100%)',
    }

}