import React, { useState } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';


function Contact() {

    const myTheme = createMuiTheme({
        overrides: {
            MuiInput: {
                underline: {
                    "&:after": {
                        borderBottom: '2px solid black',
                    }
                }
            },
        }
    });

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [messagebutton, setMessageButton] = useState('Send')
    const [values, setValues] = useState({
        name: '',
        email: '',
        message: '',
    });
    //pour modifier les états des inputs
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };



    var handleSubmit = () => {
        if (!loading) {
            setSuccess(false);
            setLoading(true);
        }
        const templateId = 'template_4m58csa';
        const serviceID = 'service_0jril8u';
        const userID = 'user_CEcA164hyxmDVvy3OW090'
        window.emailjs.send(serviceID, templateId, { message: values.message, from_name: values.name, reply_to: values.email }, userID)
            .then(function (response) {
                console.log('SUCCESS!', response.status, response.text);
                setSuccess(true);
                setLoading(false);
                setMessageButton('Message sent with success !');
                setValues({ name: '', email: '', message: '' });
            }, function (error) {
                console.log('FAILED...', error);
                setError(true)
            })
    }

    return (
                <div  style={styles.container}>
                    <div className='fade-in-top' style={{ width: '60%', alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
                        <ThemeProvider theme={myTheme}>
                            <TextField InputLabelProps={{ style: { color: 'grey' } }} style={{ marginTop: 30 }} id="name" label="Name" value={values.name} onChange={handleChange('name')} error={error} helperText={error ? "Please fill this field" : null} />
                            <TextField InputLabelProps={{ style: { color: 'grey' } }} style={{ marginTop: 30 }} id="name" label="Email" value={values.email} onChange={handleChange('email')} />
                            <TextField InputLabelProps={{ style: { color: 'grey' } }} style={{ marginTop: 30 }} multiline rows={4} id="message" value={values.message} label="message" onChange={handleChange('message')} />
                            <Button disabled={success} loading={loading} style={{ marginTop: 30, borderRadius: 0, backgroundColor: 'black', color: 'white', height: 32, width: '100%' }} onClick={() => { handleSubmit() }} startIcon={loading ? <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12, color: 'white' }} /> : null}>{loading ? null : messagebutton}</Button>
                        </ThemeProvider>
                    </div>
            </div>
    )
}
const styles = {
    container: {
        height: '105vh',
        background: 'radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(245,245,245,1) 100%)',
        position: 'relative',
        top: '-100px', /* .navbar has margin-bottom: 20px; */
        marginBottom: '-100px', /* .navbar has margin-bottom: 20px; */
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',

    },
    text: {
        alignSelf: 'center',
        width: "60%",
        fontSize: '20px',
    }
}
export default Contact