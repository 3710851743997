import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import '../App.css'

function Header(props) {
  
    return (
        <AppBar position="static" style={{ backgroundColor: 'transparent' }}>
            <Toolbar style={styles.toolbar}>
                    <h6 onClick={()=> props.handleClick('Home')} style={{ fontFamily: 'Bebas Neue', fontSize: '4vh', color: 'black', cursor:'pointer' }}>CHANEZ REKHOU</h6>
                <nav style={styles.nav}>
                    <h3 className="nav" onClick={()=> props.handleClick('About')}>About</h3>
                    <h3 className="nav" onClick={()=> props.handleClick('Contact')}>Contact</h3>
                </nav>
            </Toolbar>
        </AppBar>
    )
}

const styles = {
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: 'transparent',
        zIndex: 2,
        height: '20px'
    },
    nav: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        listStyleYype: 'none',
        color: 'black'
    }
}
export default Header
